import React, { useState, useEffect } from 'react';
import './Modal.css';
const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}/business`;

const UserForm = ({ onSubmit, initialData = true, queue_info, auto_queue, usercheck, isPhoneRequired, business_id }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    no_of_people: '',
    queue_id: '',
    queue_type: usercheck ? '' : 'waiting',
    estimated_arrival_time: '',
    table_no: '',
    table_id: '',
    remarks: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedQueueType, setSelectedQueueType] = useState('waiting');
  const [tables, setTables] = useState([]);


  const fetchTables = async (token) => {
    try {
      const response = await fetch(`${BASE_URL}/get_business_tables`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setTables(data.data || []);
    } catch (error) {
      console.error('Error fetching tables:', error);
    }
  };
  useEffect(() => {
    
    fetchTables();
  }, []);
  
  
  useEffect(() => {
    if (usercheck) {
      const savedFormData = {
        name: localStorage.getItem('name') || '',
        phone: localStorage.getItem('phone') || '',
        no_of_people: localStorage.getItem('no_of_people') || ''
      };
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...savedFormData
      }));
    }
  }, [usercheck]);

  useEffect(() => {
    if (business_id === 10 || business_id === 11) {
      setFormData(prevFormData => ({
        ...prevFormData,
        no_of_people: 1
      }));
    }
  }, [business_id]);

  useEffect(() => {
    if (initialData) {
       // Check if the queue_type is 'reserved' and set the selected queue type
      if (initialData.queue_type === 'reserved') {
        setSelectedQueueType('reserved');
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: initialData.customer_name || prevFormData.name,
        phone: initialData.phone_number || prevFormData.phone,
        no_of_people: initialData.no_of_people || prevFormData.no_of_people,
        queue_type: initialData.queue_type || prevFormData.queue_type,
        queue_id: initialData.queue_id || prevFormData.queue_id,
        table_no: initialData.table_no || prevFormData.table_no,
        estimated_arrival_time: initialData.estimated_arrival_time 
      ? new Date(initialData.estimated_arrival_time).getTime() / 1000  // Convert ISO to Unix timestamp
      : (prevFormData.estimated_arrival_time 
          ? prevFormData.estimated_arrival_time
          : ''),
        remarks: (initialData.notes?.[0]?.note_text) || prevFormData.remarks || '',
        table_id: initialData.table_id || prevFormData.table_id
        
        
      }));
    }
  }, [initialData]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    
    if (['name', 'phone', 'no_of_people', 'table_no'].includes(name)) {
      localStorage.setItem(name, value);
    }

    if (name === 'queue_type') {
      setSelectedQueueType(value); 
      if (value === 'reserved') {
        const reservedQueue = queue_info.find(queue => queue.queue_type === 'reserved');
        if (reservedQueue) {
          setFormData(prevFormData => ({
            ...prevFormData,
            queue_id: reservedQueue.queue_id
          }));
        }
      } else if (value === 'waiting') {
        setFormData(prevFormData => ({
          ...prevFormData,
          queue_id: ''
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
  
    let dataToSubmit = { ...formData };
  
    // Remove unnecessary fields based on queue type
    if (usercheck) {
      delete dataToSubmit.table_no;
      delete dataToSubmit.table_id;
    }
  
    if (selectedQueueType === 'waiting') {
      delete dataToSubmit.estimated_arrival_time;
      delete dataToSubmit.remarks;
    }
  
    // Remove queue_id if it is null or an empty string
    if (!dataToSubmit.queue_id) {
      delete dataToSubmit.queue_id;
    }
    if (!dataToSubmit.table_id) {
      delete dataToSubmit.table_id;
    }
  
    try {
      await onSubmit(dataToSubmit);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <form onSubmit={handleSubmit} className="user-form">
      <div className="form-group">
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label>Phone:</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          pattern="\d{10}"
          title="Phone number must be 10 digits long"
          placeholder="Enter 10-digit phone number"
          required={isPhoneRequired}
        />
      </div>

      {(business_id !== 10 && business_id !== 11) && (
        <div className="form-group">
          <label>No of People:</label>
          <input
            type="number"
            name="no_of_people"
            value={formData.no_of_people}
            onChange={handleChange}
            required
          />
        </div>
      )}
                  {!usercheck && (
          <>
            {tables.length > 0 ? (
                <div className="form-group">
                  <label>Table:</label>
                  <select
                    name="table_id"
                    value={formData.table_id}
                    onChange={(e) => handleChange({
                      target: {
                        name: 'table_id',
                        value: e.target.value ? parseInt(e.target.value, 10) : null // Ensure value is an integer
                      }
                    })}
                  >
                    <option value="">Select Table (Optional)</option>
                    {tables.map((table) => (
                      <option key={table.id} value={table.id}>
                        {table.name} (Status: {table.status})
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div className="form-group">
                  <label>Table No:</label>
                  <input
                    type="text"
                    name="table_no"
                    value={formData.table_no}
                    onChange={handleChange}
                    placeholder="Enter Table No (Optional)"
                  />
                </div>
              )}
          </>
        )}





      {/* Queue Type dropdown */}
          {!usercheck && (
            <div className="form-group">
              <label>Entry Type:</label>
              <select name="queue_type" value={formData.queue_type} onChange={handleChange} required>
                <option value="">Select Entry Type</option>
                <option value="waiting">Walk-in</option>
                <option value="reserved">Reservation</option>
              </select>
            </div>
          )}


{((usercheck && !auto_queue) || (!usercheck && selectedQueueType === 'waiting' && !auto_queue)) && (
            <div className="form-group">
              <label>Table Seating:</label>
              <select name="queue_id" value={formData.queue_id} onChange={handleChange} required>
                <option value="">Select Table Seating</option>
                {queue_info
                  .filter((queue) => queue.queue_type !== 'reserved') // Filter out 'reserved' queue types
                  .map((queue) => (
                    <option key={queue.queue_id} value={queue.queue_id}>
                      {queue.queue_name}
                    </option>
                  ))}
              </select>
            </div>
          )}



      {/* Conditionally render fields if the queue type is 'reserved' */}
      {selectedQueueType === 'reserved' && (
        <>
          <div className="form-group">
            <label>Estimated Arrival Time:</label>
            <input
              type="time"
              name="estimated_arrival_time"
              value={formData.estimated_arrival_time 
                ? new Date(formData.estimated_arrival_time * 1000).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) 
                : ''}
              onChange={(e) => {
                const { name, value } = e.target;
                const currentDate = new Date();  // Create a new Date object
                const [hours, minutes] = value.split(":");  // Split HH:mm
                currentDate.setHours(hours);  // Set the selected hours
                currentDate.setMinutes(minutes);  // Set the selected minutes
                currentDate.setSeconds(0);  // Set seconds to 0
            
                const epochTimeInSeconds = Math.floor(currentDate.getTime() / 1000);  // Convert to Unix timestamp
                setFormData({
                  ...formData,
                  [name]: epochTimeInSeconds,  // Update formData with the Unix timestamp
                });
              }}
              required
            />
          </div>

          <div className="form-group">
            <label>Remarks:</label>
            <input
              type="text"
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
            />
          </div>
        </>
      )}

      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </button>
    </form>
  );
};

export default UserForm;
